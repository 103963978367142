import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import commStyles from '../../pages/broadcast/commBroad.module.scss'

export default function BroadcastListItem({ children, bjData }: { children?: ReactNode; bjData: any }) {
  if (!bjData) {
    return null
  }
  return (
    <div className="subject">
      <Link to={`/broadcast/${bjData.i}`} className="post">
        <span className="nickname">
          <span className="thumnail">
            <img
              src={bjData.profile_image ? bjData.profile_image : '/images/contents/ico_logo_afreecatv.svg'}
              alt="thumn"
            />
          </span>
          <span className="nick">
            {bjData.n}
            {/* {donorData.is_afreeca ? (
              <span className="ico af">
                <img src="/images/contents/ico_logo_afreecatv.svg" alt="afreecatv" />
              </span>
            ) : (
              <></>
            )}
            {donorData.is_chzzk ? (
              <span className="ico ch">
                <img src="/images/contents/ico_logo_chzzk.svg" alt="chzzk" />
              </span>
            ) : (
              <></>
            )} */}
            {bjData.live ? <span className={`chip sm red ${commStyles.liveChip}`}>Live</span> : <></>}
            {bjData.mainCategory ? <span className="category">{bjData.mainCategory}</span> : <></>}
          </span>
        </span>
        {bjData.title ? <span className="txt">{bjData.title}</span> : <></>}
        {children}
      </Link>
    </div>
  )
}
